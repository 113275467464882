import React from 'react';
import PropTypes from 'prop-types';
import Par from '../par/par';
import { mergeClasses } from '../../../libs/helpers';
import './article-template.scss';

const ArticleTemplate = (props) => {
  const {
    className,
    title,
    children,
    parClass,
  } = props;

  const ROOT_CLASS = 'article-template';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  const parClassName = mergeClasses('article-template__par', parClass);
  const paragraphs = children.split('\n\n')
    .map((text, key) => <Par className={parClassName} key={key}>{text}</Par>);

  return (
    <div className={rootClass}>
      <div className="article-template__wrap">
        {title && <h2 className="article-template__title page__title page__title_h2">{title}</h2>}
        {paragraphs.length && paragraphs}
      </div>
    </div>
  );
};

ArticleTemplate.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  parClass: PropTypes.string,
};

ArticleTemplate.defaultProps = {
  className: '',
  title: '',
  children: null,
  parClass: '',
};

export default ArticleTemplate;
