import React from 'react';
import Image3000 from './image-3000';
import Image5000 from './image-5000';
import Image10000 from './image-10000';

export default [
  {
    nominal: '3 000 ₽',
    image: <Image3000 />,
  },
  {
    nominal: '5 000 ₽',
    image: <Image5000 />,
  },
  {
    nominal: '10 000 ₽',
    image: <Image10000 />,
  },
];
