import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../buttons/link-button/link-button';
import GiftCard from '../gift-card/gift-card';
import { Gift } from '../icons/icons';
import './gift-cards-list.scss';
import { mergeClasses } from '../../../libs/helpers';

const giftIcon = <Gift />;


const GiftCardsList = (props) => {
  const {
    className, itemClass, packageClass, items, buttonWrapClass,
  } = props;

  const ROOT_CLASS = 'gift-cards-list';
  const rootClass = mergeClasses(className, ROOT_CLASS);

  const ITEM_CLASS = `${ROOT_CLASS}__li`;
  const itemClassName = mergeClasses(itemClass, ITEM_CLASS);

  const CARD_CLASS = `${ROOT_CLASS}__card`;
  const cardClassName = mergeClasses(packageClass, CARD_CLASS);

  const NOMINAL_CLASS = `${ROOT_CLASS}__card-nominal`;

  const BUTTON_WRAP_CLASS = `${ROOT_CLASS}__button-wrap`;
  const buttonWrapClassName = mergeClasses(buttonWrapClass, BUTTON_WRAP_CLASS);

  const BUTTON_CLASS = `${ROOT_CLASS}__button`;
  const buttonClassName = mergeClasses(buttonWrapClass, BUTTON_CLASS);

  return (
    <ul className={rootClass}>
      {items.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className={itemClassName} key={key}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <GiftCard className={cardClassName} image={item.image}>
            <div className={NOMINAL_CLASS}>
              Номинал:
              {' '}
              {item.nominal}
            </div>
            <div className={buttonWrapClassName}>
              <LinkButton
                to="/"
                outline
                theme="black"
                iconRight={giftIcon}
                className={buttonClassName}
              >
                Подарить
              </LinkButton>
              <LinkButton to="/" outline theme="black" className={buttonClassName}>Купить</LinkButton>
            </div>
          </GiftCard>
        </li>
      ))}
    </ul>
  );
};

GiftCardsList.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  packageClass: PropTypes.string,
  items: PropTypes.array,
  buttonWrapClass: PropTypes.string,
};

GiftCardsList.defaultProps = {
  className: '',
  itemClass: '',
  packageClass: '',
  items: [],
  buttonWrapClass: '',
};

export default GiftCardsList;
