import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './par.scss';

const Par = (props) => {
  const { className, children } = props;

  const ROOT_CLASS = 'par';
  const rootClass = mergeClasses(ROOT_CLASS, className);

  return <p className={rootClass}>{children}</p>;
};

Par.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Par.defaultProps = {
  className: '',
  children: null,
};

export default Par;
