import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BaseButton from '../base-button/base-button';

const LinkButton = (props) => {
  const { to } = props;

  const WrappedLink = (childProps) => <Link className={childProps.className} to={to}>{childProps.children}</Link>;

  return BaseButton(WrappedLink, props);
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkButton.defaultProps = {
  className: '',
};

export default LinkButton;
