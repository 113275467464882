import React from 'react';
import PropTypes from 'prop-types';
import { mergeClasses } from '../../../libs/helpers';
import './gift-card.scss';

const GiftCard = (props) => {
  const {
    className, image, children,
  } = props;
  const ROOT_CLASS = 'gift-card';
  const WRAP_CLASS = `${ROOT_CLASS}__wrap`;
  const BODY_CLASS = `${ROOT_CLASS}__body`;
  const IMAGE_CLASS = `${ROOT_CLASS}__image-wrap`;
  const rootClass = mergeClasses(className, ROOT_CLASS);

  return (
    <div className={rootClass}>
      <div className={WRAP_CLASS}>
        <div className={IMAGE_CLASS}>{image}</div>
        {children && <div className={BODY_CLASS}>{children}</div>}
      </div>
    </div>
  );
};

GiftCard.propTypes = {
  className: PropTypes.string,
  image: PropTypes.element.isRequired,
  children: PropTypes.node,
};

GiftCard.defaultProps = {
  className: '',
  children: null,
};

export default GiftCard;
