import React from 'react';
import StandardPage from '../components/page/page-standard';
import GiftCardsList from '../components/gift-cards-list/gift-cards-list';
import ArticleTemplate from '../components/article-template/article-template';
import '../components/gift-cards-page/gift-cards-page.scss';
import items from '../__mocks__/gift-cards/gift-cards';
import { lorem } from '../__mocks__/typo';

const pageName = 'gift-cards-page';
const parText = `${lorem}\n\n${lorem}`;

const GiftCardsPage = () => {
  const MAIN_CLASS = `${pageName}__main`;
  const LIST_CLASS = `${pageName}__list`;
  const ITEM_CLASS = `${pageName}__li`;
  const ARTICLE_CLASS = `${pageName}__article`;

  return (
    <StandardPage
      pageName={pageName}
      mainClass={MAIN_CLASS}
    >
      <GiftCardsList
        className={LIST_CLASS}
        itemClass={ITEM_CLASS}
        items={items}
      />
      <ArticleTemplate
        className={ARTICLE_CLASS}
        title="Условия использования подарочных карт"
      >
        {parText}
      </ArticleTemplate>
    </StandardPage>
  );
};

export default GiftCardsPage;
